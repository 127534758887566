.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #ffffff;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #000000;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.diagram-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.diagram-label {
  font-size: 20px;
}

.location-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.location-label {
  font-size: 16px;
  height: 40px;
}

.location-content{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100px;
}

.controls {
  position: absolute;
  top: 0;
}

.left-controls {
  left: 0;
}

.right-controls {
  right: 0;
}

.diagram-container {
  margin-top: 10px;
}
